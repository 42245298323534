@import './searchbox.css';
/* TODO: Remove import after new manifests are in use */

/* RESET COMPONENT TO BE STANDALONE (in most cases) */
#page-header,
#page-header * {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none;
  text-decoration: none;
}
#page-header {
  font-family: var(--title-font);
  font-size: 100%;
  -webkit-font-smoothing: initial;
}
/* END RESET */
/* aID above header */
.wrapper-amedia-user {
  padding: 0 70px;
}
.wrapper-amedia-user amedia-user {
  max-width: 1660px;
  margin: 0 auto;
}
@media screen and (max-width: 1099px) {
  .wrapper-amedia-user {
    padding: 0 10px;
  }
}
@media screen and (max-width: 532px) {
  .wrapper-amedia-user amedia-username a:first-child {
    margin-left: -23px;
  }
}
/* Leserbrev specific */
@media screen and (max-width: 500px) {
  body#Leserbrev #page-wrap #page-header .na-logo-header {
    font-size: 24px;
  }
}

.na-logo {
  height: 60px;
  width: 60%;
}

@media screen and (max-width: 500px) {
  .na-logo {
    height: 40px;
    width: 60%;
  }
}

.na-logo img {
  height: 100%;
  width: 100%;
}

@media screen and (max-width: 500px) {
  body#Leserbrev #page-wrap #page-header .nav-wrap {
    top: 1px;
  }
}
/* Wrapper */
@media screen and (min-width: 1100px) {
  #page-header {
    padding: 0 60px;
  }
}
@media screen and (max-width: 1099px) {
  #page-header {
    padding: 0 10px;
  }
}

#page-header {
  padding-top: 40px;
  padding-bottom: var(--brick-space-x2, 10px);
  position: relative;
}

#page-header .logo-nav-wrap {
  max-width: 1660px;
  margin: 0 auto;
}

#page-header .miniheader {
  position: fixed;
  margin: initial;
  top: -80px;
  left: 0;
  width: 100%;
  max-width: 100%;
  background-color: #fff;
  padding: 16px 0;
  z-index: 10;
  opacity: 0;
  box-shadow: 0px 8px 14px rgba(0, 0, 0, 0.15);
  transition:
    top 0.2s ease-in-out,
    opacity 0.2s ease-in-out;
}
#page-header .miniheader h1 {
  font-size: 28px;
}

#forsiden-www-nettavisen-no #page-header .miniheader .na-logo-header::after {
  top: 2px;
  right: -20px;
  font-size: 7px;
  width: 17px;
  height: 23px;
}

#page-header .miniheader h1 a {
  font-size: 1em;
}
#page-header .miniheader .nav-wrap {
  top: 3px;
}
#page-header .miniheader .logo-nav-wrap {
  max-width: 1660px;
  margin: 0 auto;
  padding: 0;
  top: 0;
}
#page-header .miniheader.nav-down {
  top: 0;
  opacity: 1;
}
@media only screen and (max-width: 600px) {
  #page-header .miniheader.nav-down .menu-content.active-menu {
    overflow: auto;
    max-height: calc(100vh - 60px);
  }
}
#page-header .miniheader.nav-up {
  top: -80px;
}

#page-header .miniheader .na-logo {
  padding-bottom: 4px !important;
  padding-top: 4px !important;
}

#page-header .miniheader .logo-nav-wrapper {
  min-height: 30px;
}

#page-header .logo-nav-wrapper {
  min-height: 50px;
}

#page-header .miniheader.nav-up .menu-content.active-menu {
  pointer-events: none;
}
#page-header .logo-nav-wrapper {
  display: flex;
  justify-content: stretch;
  align-items: center;
}
#page-header h1 {
  font-weight: 400;
  display: flex;
  align-items: baseline;
  max-width: calc(100% - 70px);
  font-family: 'FaktorTittel';
  position: relative;
}

#forsiden-www-nettavisen-no #page-header .na-logo-header::after {
  font-family: 'FaktorSans';
  content: 'Øker\A mest';
  position: absolute;
  top: 8px;
  right: -30px;
  display: grid;
  place-items: center;
  background-color: #ffcc14;
  color: #000;
  font-size: 9px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 2px 5px;
  border-radius: 999px;
  white-space: pre;
  width: 27px;
  height: 33px;
  transform: rotate(20deg);
}

#page-header h1 a {
  flex: 0 0 auto;
  min-width: 0;
}

#page-header h1 a:last-child {
  padding-right: 10px;
  flex: 0 1 auto;
  white-space: nowrap;
}

/* Hide section names not part of branding */
#page-header h1 a:last-child:not(:only-child),
#page-header .miniheader h1 a:last-child:not(:only-child) {
  display: none;
}
/* Show branding section names */
#page-header .logo-nav-wrap[id='Pluss'] h1 a:last-child:not(:only-child),
#page-header .logo-nav-wrap[id='Nyheter'] h1 a:last-child:not(:only-child),
#page-header .logo-nav-wrap[id='Sport'] h1 a:last-child:not(:only-child),
#page-header .logo-nav-wrap[id*='konomi'] h1 a:last-child:not(:only-child),
#page-header .logo-nav-wrap[id='Kjendis'] h1 a:last-child:not(:only-child),
#page-header .logo-nav-wrap[id='Livsstil'] h1 a:last-child:not(:only-child), /* remove this later! */
#page-header .logo-nav-wrap[id='Sportspill'] h1 a:last-child:not(:only-child),
#page-header
  .logo-nav-wrap[id='Overgangsrykter']
  h1
  a:last-child:not(:only-child),
#page-header .logo-nav-wrap[id='Nyhetsstudio'] h1 a:last-child:not(:only-child),
#page-header .logo-nav-wrap[id*='debatt'] h1 a:last-child:not(:only-child) {
  display: inline;
}

#page-header h1 a:first-child,
#page-header h1 a:last-child {
  color: #000;
}
#page-header .logo-nav-wrap[id*='konomi'] h1 a:last-child {
  color: #0027e8;
}
#page-header #Nyheter h1 a:last-child,
#page-header .logo-nav-wrap[id*='debatt'] h1 a:last-child {
  color: #fe003d;
}
#page-header #Sport h1 a:last-child {
  color: #096;
}
#page-header #Sportspill h1 a:last-child {
  color: #096;
}
#page-header #Overgangsrykter h1 a:last-child {
  color: #096;
}
#page-header #Kjendis h1 a:last-child,
#page-header #Livsstil h1 a:last-child {
  color: #8615cc;
}
#page-header h1 a:first-child:hover {
  color: rgba(0, 0, 0, 0.7);
}
#page-header .logo-nav-wrap[id*='konomi'] h1 a:last-child:hover {
  color: rgba(0, 39, 232, 0.7);
}
#page-header #Nyheter h1 a:last-child:hover,
#page-header .logo-nav-wrap[id='Norsk debatt'] h1 a:last-child:hover {
  color: rgba(254, 0, 61, 0.7);
}
#page-header #Sport h1 a:last-child:hover {
  color: rgba(0, 153, 102, 0.7);
}
#page-header #Sportspill h1 a:last-child:hover {
  color: rgba(0, 153, 102, 0.7);
}
#page-header #Overgangsrykter h1 a:last-child:hover {
  color: rgba(0, 153, 102, 0.7);
}
#page-header #Livsstil h1 a:last-child:hover {
  color: rgba(134, 21, 204, 0.7);
}
#page-header .na-logo-header a {
  font-size: 1.413em;
}
#page-header h1 a {
  -webkit-transition: color 0.2s ease;
  transition: color 0.2s ease;
}
#page-header h1 a:hover {
  text-decoration: none;
}
#page-header .nav-wrap {
  position: relative;
  top: 10px;
  margin-left: auto;
}
#page-header .nav {
  display: inline-block;
  font-family: var(--secondary-font);
}
#page-header .nav li {
  display: inline;
  font-weight: 400;
  margin-right: 8px;
}
#page-header .nav li a {
  font-size: 1.25em;
  color: #707070;
  -webkit-transition: color 0.3s ease;
  transition: color 0.3s ease;
}
#page-header .nav li a:hover {
  color: #000;
  text-decoration: none;
}
/* Highlight menu item when on the corresponding section */
#page-header .logo-nav-wrap[id='Nyheter'] .nav li:nth-child(1) a {
  color: #000;
}
#page-header .logo-nav-wrap[id*='konomi'] .nav li:nth-child(2) a {
  color: #000;
}
#page-header .logo-nav-wrap[id='Sport'] .nav li:nth-child(3) a {
  color: #000;
}
#page-header .logo-nav-wrap[id='Kjendis'] .nav li:nth-child(4) a,
#page-header .logo-nav-wrap[id='Livsstil'] .nav li:nth-child(4) a {
  color: #000;
}

@media only screen and (max-width: 1660px) {
  #page-header .miniheader .logo-nav-wrap {
    padding: 0 60px;
  }
}
@media only screen and (max-width: 1000px) {
  #page-header .na-logo-header a {
    font-size: 1.175em;
  }

  #forsiden-www-nettavisen-no #page-header .na-logo-header::after {
    top: 7px;
    right: -27px;
    font-size: 8px;
    width: 23px;
    height: 29px;
  }

  #page-header .nav-wrap {
    top: 6px;
  }

  #page-header {
    padding-top: var(--brick-space-x1, 5px);
  }
}
@media only screen and (max-width: 768px) {
  /* #page-header{
        margin-left:20px;
        margin-right:20px
   }
    */
  #page-header.nav-down {
    margin-left: 0;
    margin-right: 0;
  }
  #page-header.nav-down .logo-nav-wrap {
    padding: 0 20px;
    margin-left: 0;
  }
  #page-header.nav-down #global-menu {
    left: 0;
  }
  #page-header .miniheader .logo-nav-wrap {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 600px) {
  #page-header {
    padding-top: var(--brick-space-x1, 5px);
    padding-bottom: var(--brick-space-x1, 5px);
  }
  #page-header .nav-wrap #global-menu button {
    font-size: 0.938em;
  }
}
@media only screen and (max-width: 460px) {
  #page-header .na-logo-header a {
    font-size: 0.96em;
  }

  #forsiden-www-nettavisen-no #page-header .na-logo-header::after {
    top: 6px;
    font-size: 8px;
    width: 20px;
    height: 26px;
  }

  #page-header .nav-wrap {
    top: 4px;
  }

  #page-header .logo-nav-wrap[id*='debatt'] h1 a:last-child:not(:only-child) {
    font-size: 6.5vw;
    font-size: clamp(1.0625rem, 6.5vw, 1.875rem);
  }
}

/* ----------- iPhone 6, 6S, 7 and 8 ----------- */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 667px) and (-webkit-min-device-pixel-ratio: 2) and (orientation: portrait) {
  #page-header .na-logo-header a {
    font-size: 0.95em;
  }

  #forsiden-www-nettavisen-no #page-header .na-logo-header::after {
    top: 3px;
    right: -24px;
    font-size: 7px;
    width: 20px;
    height: 26px;
  }

  #page-header .nav-wrap {
    top: 4px;
  }
}
/* iphone 6+, 6s+, 7+, 8+ */
@media only screen and (min-device-width: 414px) and (max-device-height: 736px) and (-webkit-device-pixel-ratio: 3) and (orientation: portrait) {
  #page-header .na-logo-header a {
    font-size: 30px;
  }
}
/* ----------- iPhone x ----------- */
/* Portrait */
@media only screen and (min-device-width: 375px) and (max-device-width: 812px) and (-webkit-min-device-pixel-ratio: 3) and (orientation: portrait) {
  body #page-header .na-logo-header {
    font-size: 30px;
  }
  body #page-header .nav-wrap {
    top: 4px;
  }
}
@media only screen and (max-width: 370px) {
  #page-header .na-logo-header a {
    font-size: 0.8em;
  }

  #forsiden-www-nettavisen-no #page-header .na-logo-header::after {
    top: -11px;
    font-size: 7px;
    width: 17px;
    height: 23px;
  }

  #page-header .nav-wrap {
    top: 2px;
  }
}
#page-header .global-menu {
  position: relative;
  display: inline-block;
  font-family: var(--secondary-font);
  font-size: 1.25em;
  width: 63px;
}
@media only screen and (max-width: 870px) {
  #page-header .nav {
    display: none;
  }

  #page-header .global-menu {
    width: auto;
  }

  #page-header .menu-content.active-menu {
    top: -25px;
  }
}
.global-menu button {
  cursor: pointer;
  font-family: var(--secondary-font);
  font-size: 1em;
  background: none;
  border: none;
  outline: none;
  position: relative;
  z-index: 10001;
  color: #000;
}
#page-header .global-menu button[aria-expanded='true'] {
  color: #fff;
}
.global-menu button:focus {
  outline: auto;
}
@media only screen and (max-width: 870px) {
  #page-header .global-menu button {
    font-size: 0.9em;
  }
}
#page-header .menu-content {
  position: absolute;
  z-index: 10000;
  top: -60px;
  right: 0;
  width: 550px;
  background-color: #000;
  padding: 25px 35px 45px;
  opacity: 0;
  pointer-events: none;
  transition:
    top 0.2s cubic-bezier(0.17, 0.04, 0.03, 0.94),
    opacity 0.2s linear;
}
#page-header .menu-content.active-menu {
  pointer-events: initial;
  top: -25px;
  opacity: 1;
}
#page-header .menu-content a,
.menu-content button {
  color: #fff;
}
#page-header .menu-content .tip-link {
  color: #ffcc14;
  font-size: 1em;
}
#page-header .menu-content button {
  float: right;
  clear: both;
}
#page-header .menu-content .navlist {
  font-size: 1.5em;
  margin-top: 50px;
}
#page-header .menu-content .navlist > li {
  margin-bottom: 10px;
  letter-spacing: -0.02em;
}
#page-header .menu-content .navlist > li::before {
  content: '●';
}
#page-header .menu-content .navlist > li.global-menu-item-red {
  color: #fe003d;
}
#page-header .menu-content .navlist > li.global-menu-item-blue {
  color: #0027e8;
}
#page-header .menu-content .navlist > li.global-menu-item-green {
  color: #096;
}
#page-header .menu-content .navlist > li.global-menu-item-purple {
  color: #8615cc;
}
#page-header .menu-content .navlist > li.global-menu-item-white {
  color: #fafafa;
}
#page-header .menu-content .navlist > li button {
  color: #999;
  font-size: 0.75em;
  padding: 15px;
  margin: -15px;
}
#page-header .menu-content .section-nav {
  margin: 15px 0 0 50px;
  overflow: hidden;
  transition:
    max-height 0.2s linear,
    opacity 0.3s linear;
  height: auto;
  max-height: 0;
  opacity: 0;
}
#page-header .menu-content .section-nav.active-submenu {
  max-height: 200px;
  opacity: 1;
}
#page-header .menu-content .section-nav li {
  font-size: 0.75em;
  margin-top: 8px;
  line-height: 1.3;
}
#page-header .menu-content .section-nav li:last-child {
  margin-bottom: 20px;
}
#page-header .menu-content .cross-section-nav {
  margin: 25px 0 0 5px;
}
#page-header .menu-content .cross-section-nav li {
  font-size: 1.25em;
  margin-top: 10px;
}
#page-header .menu-content .commercial-menu-wrap {
  margin: 45px 0 0 5px;
}
#page-header .menu-content .commercial-menu-wrap span {
  color: #999;
  font-size: 1em;
}
#page-header .menu-content .commercial-menu {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 20px;
}
#page-header .menu-content .commercial-menu li {
  font-size: 0.8em;
  flex: 1 33%;
  margin-bottom: 10px;
}
#page-header .menu-content .commercial-menu li a {
  color: #999;
  font-size: 1.25em;
}
@media only screen and (max-width: 460px) {
  #page-header .menu-content .commercial-menu li,
  #page-header .menu-content .commercial-menu li:last-child {
    flex-basis: 50%;
  }
}
@media only screen and (max-width: 1200px) {
  #page-header .menu-content {
    width: 500px;
    padding: 25px 30px 40px;
  }
  #page-header .menu-content .navlist {
    font-size: 1.5em;
    margin-top: 40px;
  }
  #page-header .menu-content .section-nav {
    margin-left: 45px;
  }
  #page-header .menu-content .section-nav li {
    font-size: 0.7em;
    margin-top: 6px;
  }
  #page-header .menu-content .cross-section-nav li {
    font-size: 1.2em;
    margin-top: 8px;
  }
}
@media only screen and (max-width: 960px) {
  #page-header .menu-content {
    width: 450px;
    padding: 20px 25px 35px;
  }
  #page-header .menu-content .navlist {
    font-size: 1.3em;
    margin-top: 30px;
  }
  #page-header .menu-content .section-nav {
    margin-left: 38px;
  }
  #page-header .menu-content .section-nav li {
    font-size: 0.8em;
  }
  #page-header .menu-content .cross-section-nav li {
    font-size: 1.1em;
  }
}
@media only screen and (max-width: 600px) {
  #page-header .menu-content {
    width: 100vw;
    padding: 20px 20px 30px;
    margin-right: -10px;
  }
  #page-header .menu-content .navlist {
    margin-top: 30px;
  }
  #page-header .menu-content .navlist > li button {
    margin: -15px;
  }
  #page-header .menu-content .cross-section-nav li {
    font-size: 1.1em;
  }
}

.sportspill-menu-container {
  max-width: 980px;
  margin: 20px auto 20px;
}

@media screen and (max-width: 1000px) {
  .sportspill-menu-container {
    margin: 10px 10px 20px;
  }
}
