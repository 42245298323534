.largeSearchBox {
  width: 100% !important;
}

amedia-searchbox-na .na {
  font-family: var(--secondary-font);
  display: block;
  background: var(--swatchMono-black4);
  padding: 10px;
  margin-top: 28px !important;
}

amedia-searchbox-na form.na {
  display: flex;
  flex-direction: row;
  border-bottom: 3px solid var(--swatchMono-white) !important;
}

amedia-searchbox-na .na .front {
  flex-wrap: wrap;
}

amedia-searchbox-na .na label {
  display: none;
}

amedia-searchbox-na .na .front label {
  display: block;
  flex-basis: 100%;
  flex: 0 0 100%;
  margin-bottom: 10px;
}

amedia-searchobox-na .na label.front {
  display: none;
}

amedia-searchbox-na .na input[type='text'] {
  box-sizing: border-box;
  border: 1px solid #000;
  background: #000;
  color: var(--swatchMono-white);
  height: 42px;
  font-size: 1rem;
  padding: 0 10px;
  flex: 1 1 0;
  width: 80%;
  outline: none;
}

amedia-searchbox-na .na button,
amedia-searchbox-na .na input[type='text'] {
  appearance: none;
  border-radius: 0;
}

amedia-searchbox-na .na button {
  background: #000000;
  color: var(--swatchMono-white);
  height: 42px;
  width: 42px;
  margin: 0;
  padding: 0;
  flex: 0 0 42px;
  position: relative;
  width: 10%;
}

amedia-searchbox-na .na button.submit:before {
  font-family: 'glyphs';
  content: '\f122';
  font-size: 15px;
  position: absolute;
  top: 12px;
  left: 14px;
  color: var(--swatchMono-white);
}

amedia-searchbox-na .na .clear,
amedia-searchbox .na .submit {
  color: var(--swatchMono-white);
  cursor: pointer;
}

amedia-searchbox-na .na .clear {
  visibility: hidden;
}

amedia-searchbox-na .na input[type='text']:-webkit-autofill {
  -webkit-box-shadow: 0 0 0px 1000px #000000 inset;
  -webkit-text-fill-color: var(--swatchMono-white);
}

amedia-searchbox-na .na input[type='text']::placeholder {
  color: var(--swatchMono-white);
  opacity: 1;
}

amedia-searchbox-na .na input[type='text']:-ms-input-placeholder {
  color: var(--swatchMono-white);
}

amedia-searchbox-na .na input[type='text']::-ms-input-placeholder {
  color: var(--swatchMono-white);
}

amedia-searchbox-na .na input:-internal-autofill-selected {
  color: -internal-light-dark-color(white, black) !important;
}
